<template>
    <div class="flex column gaps">
        <div class="flex justify-space-between">
            <div class="box self-flex-end"><h2 class="mv-0">SETTINGS</h2></div>
            <div class="box"><el-button @click="$onCommandParams('home')">BACK</el-button></div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('invoices')">
                <el-card class="flex center clickable bg-primary-light">
                    <h4 class="mv-5">Invoices Report</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('ticketsReport')">
                <el-card class="flex center clickable bg-primary-light">
                    <h4 class="mv-5">Tickets Report</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('booksReport')">
                <el-card class="flex center clickable bg-primary-light">
                    <h4 class="mv-5">Booking Report</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('capacityReport')">
                <el-card class="flex center clickable bg-primary-light">
                    <h4 class="mv-5">Capacity Report</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('clients')">
                <el-card class="flex center clickable bg-info">
                    <h4 class="mv-5">Clients</h4>
                </el-card>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('blockSession')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Block Session</h4>
                </el-card>
            </div>
            <div class="col-3"></div>
            <div class="col-3"></div>
            <div class="col-3"></div>
        </div>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('warehouse')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Warehouse</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('purchase')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Purchase</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('inventory')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Inventory</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('transaction')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Transactions</h4>
                </el-card>
            </div>
        </div>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('calendar')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Calendar</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('templates')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Templates</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('sessions')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Sessions</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('types')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Tickets Type</h4>
                </el-card>
            </div>
        </div>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('groups')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Groups</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('lesson')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Lessons</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('subscription')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Subscriptions</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('promocodes')">
                <el-card class="flex center clickable">
                    <h4 class="mv-5">Promocodes</h4>
                </el-card>
            </div>
        </div>

        <el-divider class="mv-10 o-020"></el-divider>

        <div class="box flex gaps grid-12">
            <div class="col-3" @click="$onCommandParams('users')">
                <el-card class="flex center clickable" >
                    <h4 class="mv-5">Staff</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('company')">
                <el-card class="flex center clickable locked-warning-panel">
                    <h4 class="mv-5">Companies</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('category')">
                <el-card class="flex center clickable locked-warning-panel">
                    <h4 class="mv-5">Categories</h4>
                </el-card>
            </div>
            <div class="col-3" @click="$onCommandParams('double')">
                <el-card class="flex center clickable locked-warning-panel">
                    <h4 class="mv-5">Settings</h4>
                </el-card>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  name: 'Settings'
}
</script>

<style lang="scss">
</style>
